import React, { useState } from "react";
import TaskCalendar from "./components/TaskCalendar";
import Settings from "./components/Settings";
import {
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { motion, AnimatePresence } from "framer-motion"; // Импортируем Framer Motion
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:userId" element={<Main />} />
      </Routes>
    </Router>
  );
}

const Main = () => {
  const { userId } = useParams(); // Получение userId из URL
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tasks, setTasks] = useState({});
  const [showSettings, setShowSettings] = useState(false);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  // Анимация для переходов
  const animationVariants = {
    hidden: { opacity: 0, y: -50 }, // Исходное состояние (вне экрана сверху)
    visible: { opacity: 1, y: 0 }, // Конечное состояние (на месте)
    exit: { opacity: 0, y: 50 }, // Уходящее состояние (вниз)
  };

  return (
    <div style={styles.appContainer}>
      {/* Top Navigation Bar */}
      <AppBar position="static" style={styles.appBar}>
        <Toolbar style={styles.toolbar}>
          <Typography variant="h6" style={styles.title}>
            Task Manager
          </Typography>
          <IconButton
            onClick={toggleSettings}
            color="inherit"
            style={styles.accountButton}
          >
            <AccountCircleIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container style={styles.contentWrapper}>
        <Paper elevation={3} style={styles.paper}>
          <AnimatePresence mode="wait"> {/* Анимация при смене компонентов */}
            {showSettings ? (
              <motion.div
                key="settings"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={animationVariants}
                transition={{ duration: 0.5 }}
              >
                <Settings userId={userId} />
              </motion.div>
            ) : (
              <motion.div
                key="calendar"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={animationVariants}
                transition={{ duration: 0.5 }}
              >
                <TaskCalendar
                  userId={userId} // Передача userId в TaskCalendar
                  selectedDate={selectedDate}
                  onDateChange={setSelectedDate}
                  tasks={tasks}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </Paper>
      </Container>
    </div>
  );
};

const styles = {
  appContainer: {
    backgroundColor: "#f5f5f5", // Light background color
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
  },
  appBar: {
    backgroundColor: "#4CAF50", // Attractive green color
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
    color: "#fff",
    fontWeight: 600,
  },
  accountButton: {
    color: "#fff",
  },
  contentWrapper: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "800px",
    padding: "5px 0",
  },
  paper: {
    width: "100%",
    borderRadius: "15px",
    padding: "10px",
    boxSizing: "border-box",
  },
};

export default App;
