import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CustomSwitch from "./CustomSwitch";
import GoogleIcon from "@mui/icons-material/Google"; // Импортируем иконку Google

const Settings = ({ userId }) => {
  const handleSyncToggle = (isChecked) => {
    console.log("Google Calendar Sync:", isChecked ? "Enabled" : "Disabled");
  };

  const handleGoogleConnect = () => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }
    window.location.href = `https://auth.wolfdevops.tech/auth/start/?telegram_user_id=${userId}`;
  };

  return (
    <Box style={{ padding: "20px", textAlign: "center" }}>
      <Typography variant="h5" style={{ marginBottom: "20px" }}>
        Settings
      </Typography>
      <CustomSwitch onToggle={handleSyncToggle} />
      <Button
        variant="contained"
        onClick={handleGoogleConnect}
        startIcon={<GoogleIcon />} // Добавляем иконку
        style={{
          marginTop: "20px",
          backgroundColor: "#4285F4",
          color: "#fff",
          padding: "10px 20px",
          borderRadius: "25px",
        }}
      >
        Connect to Google
      </Button>
    </Box>
  );
};

export default Settings;
