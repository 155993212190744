import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  IconButton,
} from '@mui/material';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import ListIcon from '@mui/icons-material/List';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';

const TaskDialog = ({
  open,
  onClose,
  onSave,
  task,
  setTaskTitle,
  setTaskDescription,
  setTaskSteps,
  setTaskDate,
  setTaskTime,
}) => {
  const handleStepChange = (index, value) => {
    const updatedSteps = [...(task.steps || [])];
    updatedSteps[index] = value;
    setTaskSteps(updatedSteps);
  };

  const addStepField = () => setTaskSteps([...(task.steps || []), '']);

  const removeStepField = (index) => {
    const updatedSteps = task.steps.filter((_, i) => i !== index);
    setTaskSteps(updatedSteps);
  };

  const handleSave = () => {
    onSave({
      ...task,
      date: task.date || '',
      time: task.time || '',
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle style={{ textAlign: 'center', fontSize: '1.1rem', color: '#333' }}>
        {task.title ? 'Редактировать задачу' : 'Добавить задачу'}
      </DialogTitle>
      <DialogContent style={{ padding: '8px 16px' }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <TitleIcon fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <TextField
              label="Заголовок"
              type="text"
              fullWidth
              variant="outlined"
              size="small"
              value={task.title || ''}
              onChange={(e) => setTaskTitle(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="center" style={{ marginTop: '8px' }}>
          <Grid item xs={1}>
            <DescriptionIcon fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <TextField
              label="Описание"
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
              value={task.description || ''}
              onChange={(e) => setTaskDescription(e.target.value)}
            />
          </Grid>
        </Grid>

        <Button onClick={addStepField} startIcon={<AddIcon />} style={{ marginTop: '8px', textTransform: 'none', color: '#000' }}>
          Добавить шаг
        </Button>

        <Grid container spacing={1} alignItems="center" style={{ marginTop: '8px' }}>
          <Grid item xs={1}>
            <DateRangeIcon fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <TextField
              label="Дата"
              type="date"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={task.date || ''}
              onChange={(e) => setTaskDate(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="center" style={{ marginTop: '8px' }}>
          <Grid item xs={1}>
            <AccessTimeIcon fontSize="small" />
          </Grid>
          <Grid item xs={11}>
            <TextField
              label="Время"
              type="time"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={task.time || ''}
              onChange={(e) => setTaskTime(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '8px 16px' }}>
        <Button onClick={onClose} color="secondary" style={{ textTransform: 'none' }}>
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary" style={{ textTransform: 'none' }}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskDialog;
