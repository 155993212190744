import React, { useState } from "react";
import "./CustomSwitch.css"; // Подключаем стили

const CustomSwitch = ({ onToggle }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onToggle(newCheckedState); // Вызываем колбек при изменении
  };

  return (
    <div className="switch-container">
      <div className="switch">
        <input
          type="checkbox"
          id="toggle"
          checked={isChecked}
          onChange={handleChange}
        />
        <label htmlFor="toggle">
          <i></i>
        </label>
        <span></span>
      </div>
      <div className="switch-label">
        <p>Синхронизация с Google</p>
      </div>
    </div>
  );
};

export default CustomSwitch;
