import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TaskDialog from './TaskDialog';
import ListAltIcon from '@mui/icons-material/ListAlt';
import axios from 'axios';
import { format } from 'date-fns-tz';

const TaskManager = ({ selectedDate, userId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState({ title: '', description: '', steps: [], date: '', time: '' });
  const [gifVisible, setGifVisible] = useState(false);

  const formattedDate = format(selectedDate, 'yyyy-MM-dd', { timeZone: 'Europe/Moscow' });

  const fetchTasksForDay = async () => {
    try {
      const response = await axios.post("https://auth.wolfdevops.tech/get_tasks_by_date/", {
        user_id: userId,
        date: formattedDate,
      });
      setTasks(response.data.tasks);
    } catch (error) {
      console.error('Ошибка при получении задач:', error);
    }
  };

  useEffect(() => {
    if (userId && formattedDate) {
      fetchTasksForDay();
    }
  }, [userId, formattedDate]);

  const handleAddTaskClick = () => {
    setCurrentTask({ title: '', description: '', steps: [], date: formattedDate, time: '' });
    setDialogOpen(true);
  };

  const handleSaveTask = async () => {
    const due_datetime = currentTask.time ? `${currentTask.date}` : currentTask.date;
    const task = {
      ...currentTask,
      user_id: userId,
      due_date: due_datetime,
    };

    try {
      if (task.task_id) {
        await axios.put(`https://auth.wolfdevops.tech/edit_task/${task.task_id}`, task);
      } else {
        await axios.post("https://auth.wolfdevops.tech/add_task", task);
      }
      fetchTasksForDay();
    } catch (error) {
      console.error("Ошибка при сохранении задачи:", error.response?.data || error);
    }
    setDialogOpen(false);
  };

  const handleTaskComplete = async (taskId) => {
    try {
      await axios.patch(`https://auth.wolfdevops.tech/complete_task/${taskId}`);
      fetchTasksForDay();
    } catch (error) {
      console.error('Ошибка при завершении задачи:', error);
    }
    setGifVisible(true);
    setTimeout(() => setGifVisible(false), 2000);
  };

  const handleTaskDelete = async (taskId) => {
    try {
      await axios.delete(`https://auth.wolfdevops.tech/delete_task/${taskId}`);
      fetchTasksForDay();
    } catch (error) {
      console.error('Ошибка при удалении задачи:', error);
    }
  };

  const handleEditTask = (task) => {
    const [date, time] = task.due_date ? task.due_date.split(' ') : [formattedDate, ''];
    setCurrentTask({ ...task, date, time });
    setDialogOpen(true);
  };

  return (
    <div style={styles.container}>
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        centered
        sx={{
          '& .MuiTabs-indicator': { backgroundColor: '#FFFFFF' },
          backgroundColor: '#000000',
          borderRadius: '8px',
          padding: '1px',
        }}
      >
        <Tab
          icon={<ListAltIcon />}
          label="Задачи"
          sx={{
            color: '#FFFFFF',
            fontWeight: 'bold',
            minHeight: '12px',
            padding: '1px 12px',
          }}
        />
      </Tabs>

      <Button
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={handleAddTaskClick}
        style={styles.addButton}
      >
        Добавить задачу
      </Button>

      <Grid container spacing={2} style={{ marginTop: 5 }}>
        {tasks.map((task) => (
          <Grid item xs={12} key={task.task_id}>
            <Card style={styles.taskCard(task.completed)}>
              <CardContent style={styles.cardContent}>
                <Typography variant="h6" style={styles.taskTitle}>
                  {task.title}
                </Typography>
                {task.description && <Typography variant="body2" style={styles.taskDescription}>{task.description}</Typography>}
                {task.time && (
                  <Typography variant="body2" style={styles.taskTime}>
                    {new Date(task.time).toLocaleString('ru-RU', {
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    })}
                  </Typography>
                )}
                {task.steps.length > 0 && (
                  <ul style={styles.taskSteps}>
                    {task.steps.map((step, index) => (
                      <li key={index} style={styles.taskStepItem}>{step}</li>
                    ))}
                  </ul>
                )}
              </CardContent>
              <CardActions style={styles.cardActions}>
                <IconButton onClick={() => handleTaskComplete(task.task_id)}>
                  <CheckCircleIcon style={styles.taskActionIcon(task.completed)} />
                </IconButton>
                <IconButton onClick={() => handleEditTask(task)}>
                  <EditIcon style={styles.taskActionIcon(false)} />
                </IconButton>
                <IconButton onClick={() => handleTaskDelete(task.task_id)}>
                  <DeleteIcon style={styles.deleteIcon} />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <TaskDialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onSave={handleSaveTask}
        task={currentTask}
        setTaskTitle={(value) => setCurrentTask((prev) => ({ ...prev, title: value }))}
        setTaskDescription={(value) => setCurrentTask((prev) => ({ ...prev, description: value }))}
        setTaskSteps={(value) => setCurrentTask((prev) => ({ ...prev, steps: value }))}
        setTaskDate={(value) => setCurrentTask((prev) => ({ ...prev, date: value }))}
        setTaskTime={(value) => setCurrentTask((prev) => ({ ...prev, time: value }))}
      />
    </div>
  );
};

const styles = {
  container: {
    marginTop: 10,
    fontFamily: 'Arial, sans-serif',
    width: '100%', // Фиксированная ширина
    margin: '0 auto', // Центрирование
  },
  addButton: {
    marginTop: 10,
    backgroundColor: '#000',
    color: '#FFF',
    height: '40px',
    width: '100%',
    borderRadius: '8px',
    textTransform: 'none',
  },
  taskCard: (completed) => ({
    backgroundColor: completed ? '#F0F0F0' : '#FFF',
    padding: '8px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  }),
  cardContent: {
    padding: '8px 12px',
  },
  taskTitle: {
    color: '#000',
    fontWeight: 'bold',
  },
  taskDescription: {
    color: '#666',
  },
  taskTime: {
    color: '#888',
  },
  taskSteps: {
    paddingLeft: 16,
  },
  taskStepItem: {
    color: '#444',
  },
  cardActions: {
    justifyContent: 'flex-end',
    padding: '4px 8px',
  },
  taskActionIcon: (completed) => ({
    color: completed ? '#888' : '#000',
  }),
  deleteIcon: {
    color: '#D32F2F',
  },
};

export default TaskManager;
