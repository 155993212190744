import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import axios from "axios";
import TaskManager from "./TaskManager";
import { format } from "date-fns-tz";
import { ru } from "date-fns/locale";

const TaskCalendar = ({ userId }) => {
  const [tasksByDate, setTasksByDate] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dayTasks, setDayTasks] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(
    format(new Date(), "yyyy-MM") // Инициализируем текущим месяцем
  );

  useEffect(() => {
    console.log("Fetching tasks for:", userId, currentMonth);
    const fetchMonthlyTasks = async () => {
      if (!userId || !currentMonth) {
        console.error("Missing userId or month");
        return;
      }

      try {
        const response = await axios.post(
          "https://auth.wolfdevops.tech/get_tasks_for_calendar/",
          {
            user_id: parseInt(userId, 10),
            month: currentMonth,
          }
        );
        console.log("Tasks fetched:", response.data);
        setTasksByDate(response.data.tasks || {});
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchMonthlyTasks();
  }, [currentMonth, userId]);

  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Обновляем задачи для выбранного дня
    const dateString = format(date, "yyyy-MM-dd", { timeZone: "Europe/Moscow" });
    setDayTasks(tasksByDate[dateString] || []);
  };

  const handleMonthChange = (month) => {
    const formattedMonth = format(month, "yyyy-MM");
    setCurrentMonth(formattedMonth); // Обновляем текущий месяц
  };

  const hasTasks = (date) => {
    const dateString = format(date, "yyyy-MM-dd", { timeZone: "Europe/Moscow" });
    return Boolean(tasksByDate[dateString]);
  };

  const modifiers = {
    hasTasks: (date) => hasTasks(date),
    selected: selectedDate,
  };

  const modifiersStyles = {
    hasTasks: {
      border: "2px solid #4CAF50", // Зеленая обводка для дней с задачами
      borderRadius: "50%",
    },
    selected: {
      backgroundColor: "#222",
      color: "#fff",
      borderRadius: "50%",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.calendarWrapper}>
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={handleDateChange}
          onMonthChange={handleMonthChange} // Обработчик смены месяца
          modifiers={modifiers}
          modifiersStyles={modifiersStyles}
          locale={ru} // Установка русского языка
          styles={{
            caption: {
              color: "black",
              backgroundColor: "#fff",
              borderRadius: "5px",
              textAlign: "center",
            },
            head: {
              color: "black",
              backgroundColor: "#f3f3f3",
            },
            body: {
              backgroundColor: "#fff",
            },
            day: {
              borderRadius: "5px",
              textAlign: "center",
            },
          }}
        />
      </div>
      <TaskManager userId={userId} selectedDate={selectedDate} tasks={dayTasks} />
      <style jsx>{`
        @media (max-width: 600px) {
          .DayPicker {
            width: 100%; /* Ограничиваем ширину на маленьких экранах */
            margin: 0 auto; /* Центрируем */
            font-size: 0.9rem; /* Уменьшение размера шрифта */
          }
        }
      `}</style>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#f3f3f3", // Светлый фон
    boxSizing: "border-box",
  },
  calendarWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#fff", // Белый фон для календаря
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Легкая тень
    width: "100%", // Делаем адаптивным на мобильных
    maxWidth: "400px", // Максимальная ширина для больших экранов
    boxSizing: "border-box",
    margin: "0 auto", // Центрируем на маленьких экранах
  },
};

export default TaskCalendar;
